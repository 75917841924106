import { Button } from '../index';

const BUTTON_COLORS: string[] = ['#5B5B5B', '#FF3737', '#373737'];

let lightKey1 = "url('assets/images/numpad/light_key_off.png')";
let lightKey2 = "url('assets/images/numpad/light_key_on.png')";
let darkKey1 = "url('assets/images/numpad/dark_key_off.png')";
let darkKey2 = "url('assets/images/numpad/dark_key_on.png')";

let payoutKey1 = "url('assets/images/payout/payout_keys_off.png')";
let payoutKey2 = "url('assets/images/payout/payout_keys_on.png')";
let spaceKey1 = "url('assets/images/payout/payout_keys_space_off.png')";
let spaceKey2 = "url('assets/images/payout/payout_keys_space_on.png')";

export const BUTTONS1: Button[][] =
    [
        [{ "value": "1", "background1": lightKey1, "background2": lightKey2 }, { "value": "2", "background1": lightKey1, "background2": lightKey2 },
        { "value": "3", "background1": lightKey1, "background2": lightKey2 }],
        [{ "value": "4", "background1": lightKey1, "background2": lightKey2 }, { "value": "5", "background1": lightKey1, "background2": lightKey2 },
        { "value": "6", "background1": lightKey1, "background2": lightKey2 }],
        [{ "value": "7", "background1": lightKey1, "background2": lightKey2 }, { "value": "8", "background1": lightKey1, "background2": lightKey2 },
        { "value": "9", "background1": lightKey1, "background2": lightKey2 }],
        [{ "value": ".", "background1": darkKey1, "background2": darkKey2 }, { "value": "0", "background1": lightKey1, "background2": lightKey2 },
        { "value": "ALL", "background1": darkKey1, "background2": darkKey2 }]
    ];

export const BUTTONS2: Button[][] =
    [
        [{ "value": "1", "background1": lightKey1, "background2": lightKey2 }, { "value": "2", "background1": lightKey1, "background2": lightKey2 },
        { "value": "3", "background1": lightKey1, "background2": lightKey2 }],
        [{ "value": "4", "background1": lightKey1, "background2": lightKey2 }, { "value": "5", "background1": lightKey1, "background2": lightKey2 },
        { "value": "6", "background1": lightKey1, "background2": lightKey2 }],
        [{ "value": "7", "background1": lightKey1, "background2": lightKey2 }, { "value": "8", "background1": lightKey1, "background2": lightKey2 },
        { "value": "9", "background1": lightKey1, "background2": lightKey2 }],
        [{ "value": "CA", "background1": darkKey1, "background2": darkKey2 }, { "value": "0", "background1": lightKey1, "background2": lightKey2 },
        { "value": "C", "background1": darkKey1, "background2": darkKey2 }]
    ];

export const BUTTONS3: Button[][] =
    [
        [{ "value": "1", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "2", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "3", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "4", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "5", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "6", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "7", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "8", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "9", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "0", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "-", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "< delete", "background1": payoutKey1, "background2": payoutKey2 }],

        [{ "value": "Q", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "W", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "E", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "R", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "T", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "Y", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "U", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "I", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "O", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "P", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "'", "background1": payoutKey1, "background2": payoutKey2 }],

        [{ "value": "A", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "S", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "D", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "F", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "G", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "H", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "J", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "K", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "L", "background1": payoutKey1, "background2": payoutKey2 }],

        [{ "value": "Z", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "X", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "C", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "V", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "B", "background1": payoutKey1, "background2": payoutKey2 }, { "value": "N", "background1": payoutKey1, "background2": payoutKey2 },
        { "value": "M", "background1": payoutKey1, "background2": payoutKey2 }]
    ];

export const BUTTON_SPACE = { "value": "space", "background1": spaceKey1, "background2": spaceKey2 };