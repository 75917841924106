import { ConfigGroup } from './config-group';
import { BetType } from './bet-type';


export class BetTypeQuotas {

    getQuotaFromConfig(config: ConfigGroup, betType: BetType, combinations?: number): any {
        if (betType == null || betType === undefined) {
            throw new Error('Bet type is not defined!');
        }
        if (!combinations) {
            combinations = 1;
        }
        switch (betType) {
            case BetType.FirstBallColor:
                return config.colorQuotas[combinations];
            case BetType.FirstBallUnderOver:
                return config.oFirstUnderOver_24_5;
            case BetType.FirstBallEvenOdd:
                return config.oFirstOddEven;
            case BetType.NumberAmongFirstFive:
                return config.oFirstIn_5;
            case BetType.SumOfFirstFiveUnderOver:
                return config.oFirst_5_UnderOver_122_5;
            case BetType.FirstFiveEvenOdd:
                return config.oFirst_5_OddEven;
            default:
                return 0;
        }
    }

}
