import { AllColorsBetContent } from './all-colors-bet-content';
import { IBetContent } from './bet-content';
import { NumbersBetContent } from './numbers-bet-content';
import { ColorsBetContent } from './colors-bet-content';
import { StringBetContent } from './string-bet-content';
import { BetType } from './bet-type';

/**
* Factory pattern for create objects of IBetContent.
*/
export class BetContentFactory {

    /**
    * Creates IBetContent object depending on a bet type.
    */
    public static create(betType: BetType, selection: any): IBetContent {
        if (betType === null || betType === undefined) {
            throw new Error('Bet type is not defined!');
        }
        switch (betType) {
            case -1:
            case BetType.SimpleBetting:
            case BetType.SystemBetting:
                return new NumbersBetContent(betType, selection);
            case BetType.FirstBallColor:
                return new ColorsBetContent(selection);
            case BetType.AllColors:
                return new AllColorsBetContent(selection);
            default:
                return new StringBetContent(betType, selection);
        }

    }

}
