
import {timeout} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Ticket } from '../model';
import { CrudService, BaseService, BaseEntity, AuthService } from '../common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TicketValidator } from '../util/ticket-validator';

@Injectable()
export class CrudTicketHttService extends CrudService<Ticket> {

    constructor(http: HttpClient, private auth: AuthService) {
        super('tickets', http);
    }

    getLastTickets(size?: number, ticketId?: number) {
        let params: HttpParams = new HttpParams();

        if (size) {
            params = params.set('size', size.toString());
        }
        if (ticketId) {
            params = params.set('ticketId', ticketId.toString());
        }
        return this.http.get<Ticket[]>(this.uri + '/usertickets', this.getOptions(params)).pipe(timeout(10000));
    }

    getFilterTickets() {
        return this.http.get<Ticket[]>(this.uri + '/filterTickets').pipe(timeout(10000));
    }

    confirm(entity: BaseEntity) {
        return this.http.post<Boolean>(this.uri + '/confirm', entity, this.getOptions());
    }

    getTicketByPin(ticketPin) {
        let params: HttpParams = new HttpParams();
        const token = this.auth.getToken();

        if (token) {
            params = params.set('token', token);
        }

        return this.http.get<any>(this.uri + '/ticket' + '/' + ticketPin, this.getOptions(params)).pipe(timeout(10000));
    }

    getFastTicket(ticketCode) {
        let params: HttpParams = new HttpParams();
        const token = this.auth.getToken();

        if (token) {
            params = params.set('token', token);
        }

        return this.http.get<any>(this.uri + '/quick' + '/' + ticketCode, this.getOptions(params)).pipe(timeout(10000));
    }
}
