import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class CustomTranslateService {
  public static translate;
  public lang: string;
  constructor(translate: TranslateService) {
    CustomTranslateService.translate = translate;
    CustomTranslateService.translate.setDefaultLang('rs');
  }
  setLang(lang: string) {
    this.lang = lang;
    CustomTranslateService.translate.use(lang);
  }
}