import { TicketPayoutType } from "../model/ticket-payout-type";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../common";

@Injectable()
export class PayoutTicketService{

    payoutType: TicketPayoutType;
    public uri = 'ticket';

    constructor(protected http: HttpClient) {
    }

    payoutToCash(ticketPin: string) {
        return this.http.post<Boolean>(this.uri + '/payout', {ticketPin});
    }
}