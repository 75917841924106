import { TicketBet } from './ticket-bet';

import { BaseEntity } from '../common';
import { TicketStatus } from './ticket-status';
import { Currency } from './currency';

/**
* Information about a ticket.
*/
export class Ticket extends BaseEntity {
    static createCopy(objectToCopy: Ticket): Ticket{
        return (JSON.parse(JSON.stringify(objectToCopy)));
    }

    ticketId: number;
    companyName: string;

    /**
     * Ticket PIN (to be printed as barcode).
     */
    ticketPin?: string;

    /**
    * Pay in amount for this ticket.
    */
    payInAmount: number;

    numberOfRound?: number;

    drawRoundsFormatted?: string;

    /**
    * Draw round for which the ticket is purchased.
    */
    drawRoundNumber?: number;

    /**
     * Current status of the ticket.
     */
    status?: TicketStatus;

        /**
    * Currency for the ticket. 
    */
   currency?: Currency;

    /**
     * Paid out amount, if the ticket was paid out. Otherwise 0.
     */
    payoutAmount?: number;

    maxWinAmount?: number;

    isBettingInAdvance?: boolean;

    /**
     * Bets on this ticket
     */
    bets?: TicketBet[];
    /**
    * Child tickets, not null for in advance betting.
    */
    childTickets?: Ticket[];

    winAmount?: number;

    totalPayin?: number;
    feeOfBetting?: number;
    payinTax?: number;

    isJackpotWinner?: boolean;
    jackpotWinningAmount?: any;
    jackpotNumber?: string;
    winTaxAmount?: number;

    betsCount?: number;
    combinationsCount?: number;

    drawRoundTimeUtc?: string;

    drawRoundTimeFormatted?: string;
    
    getId(): number {
        return this.ticketId;
    }
}
