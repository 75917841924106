import { BetsData } from '../component/model/data/bets-data';

export class SelectionConverter {

    public static convertNumbersToString(numbers: number[]): string {
        let selectionBuilder = '';
        numbers.forEach(element => {
            selectionBuilder += ', ' + element;
        });
        selectionBuilder = selectionBuilder.substring(2);
        return selectionBuilder;
    }

    public static convertStringToNumbers(selection: string): number[] {
        selection = selection.replace(' ', '');
        const array = selection.split(',');
        const numbers = new Array<number>();
        array.forEach(element => {
            numbers.push(Number(element));
        });
        return numbers;
    }

    public static convertSelectionToNumbers(selection: string): number[] {
        const array = selection.split(',');
        const numbers = new Array();
        array.forEach(element => {
            numbers.push(Number(element));
        });
        return numbers;
    }

    public static colorOfNumbersSelection(selection: string): string {
        const numbers = this.convertSelectionToNumbers(selection);
        if (numbers.length !== 6) {
            return null;
        }
        let previousNumber;
        let sameColor = true;
        numbers.forEach(element => {
            if (previousNumber && element - previousNumber !== 8) {
                sameColor = false;
            }
            previousNumber = element;
        });
        if (sameColor) {
            return BetsData.getColorSelections()[numbers[0] - 1].value;
        }
        return null;
    }

    public static convertSelectionToColors(selection: string): string[] {
        const array = selection.split(',');
        const numbers = new Array();
        array.forEach(element => {
            numbers.push(element.trim());
        });
        return numbers;
    }

    public static convertSelectionToOddEven(selection: number): string {
        if (selection == 1)
            return 'odd';
        else if (selection == 2)
            return 'even';
    }

    public static convertSelectionTounderOver(selection: number): string {
        if (selection == 1)
            return 'under';
        else if (selection == 2)
            return 'over';
    }
}
