/**
 * Round Result, containt all info about drawn numbers, winning bets and Jackpot.
 */
export class RoundResult {

    result: string;

    sweetSpot1: number;

    sweetSpot2: number;

    jackpotGroupId: number;

    isJackpot: boolean;

    currentAmount: number;

    firstBallColor: string;

    firstBallEvenOdd: string;

    firstBallUnderOver: string;

    colorOfBalls: string[];

    firstFiveEvenOdd: string;

    sumOfFirstFiveUnderOver: string;

    jackpotWinningAmount: number;

    jackpotWinnerNumber: string;

}
