import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HOME_ROUTE, AuthService, LoginRequest, LoginService } from '../../common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  username: string;
  password: string;

  label: string;
  returnUrl: string = HOME_ROUTE;

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService, private loginService: LoginService) {

  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/' + HOME_ROUTE;
  }

  ngOnDestroy() {
  }

  login(username: any, password: any) {
    let loginRequest = new LoginRequest();
    loginRequest.username = username;
    loginRequest.password = password;

    this.loginService.login(loginRequest)
      .subscribe((resp) => {
        this.router.navigate([this.returnUrl]);
        // this.setCurrentUser();
      },
        (error: any) => {
          if (error.status && error.status == "401") {
            // this.messageService.errorGrowl('Neuspešna prijava!', 'Korisničko ime ili lozinka nisu ispravni.');
          } else {
            console.log(error)
            // this.messageService.errorGrowl('Neuspešna prijava!', error.message);
          }
        }
      );
  }
}
