import { Injectable } from '@angular/core';
import { BALL_COLORS_VALUES, Ball } from '../component/model/index';

@Injectable()
export class ResourcesLoaderService {

    images = new Array();

    constructor() {
    }

    private preloadImages(args: any) {
        for (let i = 0; i < args.length; i++) {
            const index = this.images.push(new Image());
            this.images[index - 1].src = args[i];
        }
    }

    preloadBettingResources() {
        const balls = [];
        const ball = new Ball(-1, 'color', null);
        BALL_COLORS_VALUES.forEach(element => {
            balls.push(ball.baseBallsUrl + element + '.png');
        });
        this.preloadImages(balls);
    }

    preloadGameResources() {
        const balls = [];
        for (let i = 0; i < 48; i++) {
            balls.push('./assets/images/balls/touch/t_' + (i + 1) + '_on.png');
        }
        for (let i = 0; i < 48; i++) {
            balls.push('./assets/images/balls/large/l_' + (i + 1) + '.png');
        }

        balls.push('./assets/images/sweetSpot1.png');
        balls.push('./assets/images/hole.png');
        balls.push('./assets/images/clover_ring3.png');
        balls.push('./assets/images/122_5_ruler_x2.png');
        balls.push('./assets/images/background.png');
        balls.push('./assets/images/results_background.png');
        balls.push('./assets/images/logo.png');
        this.preloadImages(balls);
    }

    preloadCountingResources() {
        const balls = [];
        
        balls.push('./assets/images/results_background.png');
        balls.push('./assets/images/logo.png');
        this.preloadImages(balls);
    }

}
