import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class DrawingService {

    private subject = new Subject<any>();

    sendData(ballNumber: number, i: number) {
        this.subject.next({ ballNumber: ballNumber, ballIndex: i });
    }

    clearData() {
        this.subject.next();
    }

    getData(): Observable<any> {
        return this.subject.asObservable();
    }

}
