import { TicketBet } from './ticket-bet';
import { Ticket } from './ticket';


export class TicketBuilder {

    private ticket: Ticket;

    constructor() {
        this.createTicketIfNull();
    }

    createTicketIfNull(): void {
        if (!this.ticket) {
            this.ticket = new Ticket();
        }
    }

    build(): Ticket {
        this.createTicketIfNull();
        return this.ticket;
    }

    setBets(bets: TicketBet[]): TicketBuilder {
        this.createTicketIfNull();
        this.ticket.bets = bets;
        return this;
    }

    setAmount(amount: number): TicketBuilder {
        this.createTicketIfNull();
        this.ticket.payInAmount = amount;
        return this;
    }

    setRound(round: number): TicketBuilder {
        this.createTicketIfNull();
        this.ticket.drawRoundNumber = round;
        return this;
    }

    setTicketInAdvance(numberOfRounds: number): TicketBuilder {
        this.createTicketIfNull();
        this.ticket.numberOfRound = numberOfRounds;
        return this;
    }

}
