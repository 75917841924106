import { TOKEN_KEY, PARAM_USERNAME } from './../constants';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

@Injectable()
/**
 * Proxy class for operations with token, that is stored in Local Storage.
 */
export class AuthService {
    /**
     * Indicates wheter game is in real mode or in demo mode.
     */
    realGameMode = false;

    public getToken(): string {
        const token = sessionStorage.getItem(TOKEN_KEY);
        if (token) {
            return token;
        }
        return null;
    }

    public isAuthenticated(): boolean {
        const token = this.getToken();
        if (!token) {
            return false;
        }
        return true;
    }

    public saveUserData(tokenResponse: HttpResponse<any>, username) {
        this.realGameMode = true;
        sessionStorage.setItem(TOKEN_KEY, tokenResponse.body.token);
        sessionStorage.setItem(PARAM_USERNAME, username);
    }

    public removeUserData() {
        sessionStorage.removeItem(TOKEN_KEY);
        sessionStorage.removeItem(PARAM_USERNAME);
        this.realGameMode = false;
    }

}
