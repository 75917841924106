import { IBetContent } from './bet-content';
import { BetType } from './bet-type';
import { SelectionConverter } from './selection-converter';

export class NumbersBetContent extends IBetContent {

    static maxPick = 10;
    selection: Array<number>;

    /**
     * When betType is -1, it is considered a Random ball selection.
     * 0 is for SimpleBetting, and 1 is SystemBetting.
     * @param betType
     * @param numbers
     */
    constructor(betType: BetType, numbers?: Array<number>) {
        super(betType);
        this.selection = new Array<number>();
        if (numbers) {
            if (numbers instanceof Array) {
                this.selection.push.apply(this.selection, numbers);
            } else {
                this.selection.push(numbers);
            }
            this.sortArray();
        }
        if (this.selection.length === 6 && betType > -1) {
            this.betType = BetType.SimpleBetting;
        } else if (this.selection.length > 6) {
            this.betType = BetType.SystemBetting;
        }
    }

    onValueSelected(value: any): any {
        if (this.isValueSelected(value)) {
            this.selection.splice(this.selection.indexOf(value), 1);
            if (this.selection.length <= 6) {
                this.betType = BetType.SimpleBetting;
            }
            return value;
        } else {
            if (this.selection.length < NumbersBetContent.maxPick) {
                const index = 0;
                this.selection.push(value);
                this.sortArray();
                if (this.selection.length > 6) {
                    this.betType = BetType.SystemBetting;
                }
            }
        }
    }

    private sortArray(): void {
        this.selection.sort(function (a, b) { return a - b; });
    }

    isValueSelected(value: any): boolean {
        if (typeof value !== 'number') {
            return false;
        }
        if (this.selection.indexOf(value) > -1) {
            return true;
        }
        return false;
    }

    isEmpty(): boolean {
        if (this.selection && this.selection.length > 0) {
            return false;
        }
        return true;
    }

    isBetValid(): boolean {
        if (this.selection && this.selection.length > 5 && this.selection.length <= NumbersBetContent.maxPick) {
            return true;
        }
        return false;
    }

    isEqualsBetType(betType: BetType): boolean {
        if (betType < 2) {
            return true;
        }
        return false;
    }

    getSelection(): string {
        if (!this.selection) {
            return null;
        }
        return SelectionConverter.convertNumbersToString(this.selection);
    }

    getNumberOfCombinations(): number {
        if (this.selection) {
            switch (this.selection.length) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    return 1;
                case 7:
                    return 7;
                case 8:
                    return 28;
                case 9:
                    return 84;
                case 10:
                    return 210;
            }
        }
        return 0;
    }

    clear(): void {
        this.selection = new Array<number>();
    }

}
