import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { LoginRequest } from '../model/login-request';
import { BaseService } from '../service/base.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class LoginService extends BaseService {

    protected uri = 'account';

    constructor(protected http: HttpClient, private auth: AuthService) {
        super();
    }

    /**
* Method that calls a backend server with request params username and password
* and returns session response if request is valid.
* @param request
*/
    login(request: LoginRequest): Observable<any> {
        return this.http.post(this.uri, request, { ...this.getOptions(), observe: 'response' }).pipe(
            tap((response: HttpResponse<any>) => {
                this.auth.saveUserData(response, request.username)
                return response;
            },
                err => {
                    this.auth.removeUserData();
                    return err;
                }));
    }

}
