import { HOME_ROUTE, LOGIN_ROUTE } from './../constants';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
/**
 * Filter class, checks if token is present and valid. If true, user is allowed to access desired resource.
 */
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private auth: AuthService, private router: Router) { }

    /**
     * When app navigates to some route this method is triggered to check if user has a token and to check if it is still active.
     * If token is valid, method returns true and continues loading of the page.
     * If token is not present or it has expired, method redirects to home (auth) page (component).
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.auth.isAuthenticated()) {
            return true;
        }
        this.auth.removeUserData();
        this.router.navigate([LOGIN_ROUTE], { queryParams: { returnUrl: state.url } });
        return false;
    }

    /**
     * Calls canActivate method.
     * @param route
     * @param state
     */
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

}
