
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {timeout} from 'rxjs/operators';

import { DrawRound, DrawRoundsStatistics } from '../model';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { BaseService } from '../common';

@Injectable()
export class DrawRoundService extends BaseService {

    public uri = 'round';

    constructor(protected http: HttpClient) {
        super();
    }

    getNext(numberOfRounds: number): Observable<DrawRound[]> {
        let params = new HttpParams();
        params = params.set('size', numberOfRounds.toString());
        return this.http.get<any>(this.uri + '/next', this.getOptions(params)).pipe(timeout(10000));
    }

    getFinished(size?: number) {
        let params = new HttpParams();
        if (size) {
            params = params.set('size', size.toString());
        }
        return this.http.get<DrawRound[]>(this.uri + '/numbersfinished', super.getOptions(params)).pipe(timeout(10000));
    }

    getAll(size?: number) {
        let params = new HttpParams();
        if (size) {
            params = params.set('size', size.toString());
        }
        return this.http.get<DrawRound[]>(this.uri + '/result/finished', super.getOptions(params)).pipe(timeout(10000));
    }

    getFinishedById(roundId: number): Observable<DrawRound> {
        if (roundId === undefined || roundId == null) {
            return observableThrowError('Value cannot be undefined');
        }
        let params = new HttpParams();
        params = params.set('roundId', roundId.toString());
        return this.http.get<DrawRound>(this.uri + '/result/finished', super.getOptions(params)).pipe(timeout(10000));
    }

    getCurrentStatus() {
        return this.http.get<any>(this.uri + '/currentstatus', this.getOptions()).pipe(timeout(10000));
    }

    getStatistics(numberOfRounds?: number, hours?: number) {
        let params = new HttpParams();
        if (numberOfRounds) {
            params = params.set('rounds', numberOfRounds.toString());
        }
        if (hours) {
            params = params.set('hours', hours.toString());
        }
        return this.http.get<DrawRoundsStatistics>(this.uri + '/statistic', this.getOptions(params)).pipe(timeout(10000));
    }

    getJackpotAmount(group: string) {
        return this.http.get<any>(this.uri + '/jackpotamount' + group, this.getOptions()).pipe(timeout(10000));
    }

}
