import { BetType } from './bet-type';

/**
* Every class that contains some BetType content should extend this class.
*/
export abstract class IBetContent {

    betValid: boolean;

    constructor(public betType?: BetType, public selection?: any) {
    }

    /**
    * User clicked on this value on UI, and implementation should decide, depending on the state if it should add the value or remove it.
    * If another value is deleted from selection it will return it.
    */
    abstract onValueSelected(value: any): any;

    /**
    * Returns boolean (true/false) if value param is selected.
    */
    abstract isValueSelected(value: any): boolean;

    /**
* Returns false if the bet has some value in selection, otherwise true.
*/
    abstract isEmpty(): boolean;

    abstract isBetValid(): boolean;

    /**
    * Returns bet selection as a string.
    */
    abstract getSelection(): string;

    /**
    * Returns number of combinations in a bet.
    */
    getNumberOfCombinations(): number {
        return 1;
    }

    isEqualsBetType(betType: BetType): boolean {
        return this.betType === betType;
    }

    /**
    * Clear bet selection, set it to undefined.
    */
    abstract clear(): void;

}
