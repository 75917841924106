import { ConfigGroupService } from './config-group.service';
import { Injectable } from '@angular/core';
import { BetParams, IBetContent, BetType } from '../model/index';

@Injectable()
export class BetParamsService {

  private numbersSet: Set<number>;

  currentBetParams: BetParams;
  numberOfRounds = 1;
  ticketPayin = 10;

  constructor(private configGroupService: ConfigGroupService) {
    this.numbersSet = new Set<number>();
    this.numbersSet.add(-1);
    this.numbersSet.add(BetType.SimpleBetting);
    this.numbersSet.add(BetType.SystemBetting);
    this.numbersSet.add(BetType.ColorOfBalls);
  }

  resetParams() {
    this.calculateBetParams(null);
    if (this.configGroupService.config) {
      this.numberOfRounds = 1;
      this.ticketPayin = this.configGroupService.config.minPayin;
    } else {
      this.numberOfRounds = 1;
      this.ticketPayin = 2;
    }
  }

  calculateBetParams(bets: IBetContent[]) {
    this.currentBetParams = new BetParams();
    if (this.configGroupService.config) {
      this.currentBetParams.numberOfComb = this.calculateNumberOfCombinations(bets, this.numberOfRounds);
      this.currentBetParams.minPayin = this.calculateMinPayin();
      this.currentBetParams.maxPayin = this.calculateMaxPayin();
      if (this.ticketPayin) {
        if (this.ticketPayin > this.currentBetParams.maxPayin) {
          this.ticketPayin = this.currentBetParams.maxPayin;
        }
        if (this.ticketPayin < this.currentBetParams.minPayin) {
          this.ticketPayin = this.currentBetParams.minPayin;
        }
        let numberOfComb = this.currentBetParams.numberOfComb;
        if (!this.currentBetParams.numberOfComb) {
          numberOfComb = 1;
        }
        this.currentBetParams.payinPerComb = this.ticketPayin / numberOfComb;
      } else {
        this.currentBetParams.payinPerComb = this.configGroupService.config.minPayin;
      }
    }
  }

  private calculateNumberOfCombinations(bets: IBetContent[], numberOfRounds: number) {
    let comb = 1;
    if (bets && bets.length > 0) {
      comb = 0;
      bets.forEach(
        element => {
          if (element.isBetValid()) {
            if (this.numbersSet.has(element.betType)) {
              comb += element.getNumberOfCombinations();
            } else {
              comb++;
            }
          }
        });
    }
    return comb * numberOfRounds;
  }

  private calculateMinPayin(): number {
    let numberOfComb = this.currentBetParams.numberOfComb;
    if (!this.currentBetParams.numberOfComb) {
      numberOfComb = 1;
    }
    return numberOfComb * this.configGroupService.config.minPayin;
  }

  private calculateMaxPayin(): number {
    let numberOfComb = this.currentBetParams.numberOfComb;
    if (!this.currentBetParams.numberOfComb) {
      numberOfComb = 1;
    }
    return numberOfComb * this.configGroupService.config.maxPayin;
  }

}
