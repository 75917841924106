import { Dictionary } from '../../model/index';

export class StatView {
    title: string;
    dictionary: Dictionary;
    viewType: StatViewType;
}

export enum StatViewType {
    BallsView = 0,

    BarView = 1,

    ColorsView = 2
}
