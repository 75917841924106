import { SelectionView } from './index';
import { BetType } from '../../model/index';

export class BetView {

    desc: string;
    type: BetType;
    quota?: number;
    selections: SelectionView[];
}
