import { OnDestroy, OnInit } from '@angular/core';

export abstract class BaseComponent implements OnInit, OnDestroy {

    protected subscriptions: any[] = [];

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

}
