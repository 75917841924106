import { Dictionary } from './dictionary';

export class DrawRoundsStatistics {

    /**
     * Dicitonary type. Key is number, and value is number of occurencies. Attributes are sorted asc.
     */
    drawnMostTimes: Dictionary;

    /**
     * Dicitonary type. Key is number, and value is number of occurencies. Attributes are sorted desc.
     */
    drawnLeastTimes: Dictionary;

    firstNumberParity: Dictionary;

    firstNumber24: Dictionary;

    firstFiveParity: Dictionary;

    firstFiveSum122: Dictionary;

    /**
     * Dicitonary type. Key is color name, and value is number of occurencies.
     */
    firstBallColor: Dictionary;

}
