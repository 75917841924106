import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HOME_ROUTE, GAME_ROUTE, LOGIN_ROUTE, AuthGuard, PRC_GAME_ROUTE, PRC_HOME_ROUTE, PAYOUT_ROUTE } from './common/index';
import { LoginComponent } from './component/login/login.component';

const appRoutes: Routes = [
  {
    path: LOGIN_ROUTE,
    component: LoginComponent
  }, {
    path: HOME_ROUTE,
    canActivate: [AuthGuard],
    loadChildren: './component/home/home.module#HomeModule'
  }, {
    path: PRC_HOME_ROUTE,
    loadChildren: './component/home/home.module#HomeModule'
  },
  {
    path: GAME_ROUTE,
    canActivate: [AuthGuard],
    loadChildren: './component/game/game.module#GameModule'
  },
  {
    path: PRC_GAME_ROUTE,
    loadChildren: './component/game/game.module#GameModule'
  },
  {
    path: PAYOUT_ROUTE,
    canActivate: [AuthGuard],
    loadChildren: './component/ticket-payout/ticket-payout.module#TicketPayoutModule'
  },
  {
    path: '**',
    redirectTo: HOME_ROUTE,
    pathMatch: 'full'
  }
];

export const Routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
