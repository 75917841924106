import { Injectable } from '@angular/core';
import { DrawRoundStatus } from '../model/draw-round-status';

@Injectable()
export class RoundService {

    drawRound: DrawRoundStatus;
    temp: any;

    constructor() { }

    private time2;

    setData(drawRound) {
        this.drawRound = drawRound;
    }

    getData() {
        this.temp = this.drawRound;
        // this.clearData();
        return this.temp;
    }

    clearData() {
        this.drawRound = null;
    }

}
