
import {timer as observableTimer,  Observable ,  Subject } from 'rxjs';
import { ConfigGroupService } from './config-group.service';
import { TimeUtil } from './../util/time-util';
import { Injectable } from '@angular/core';

import { DrawRound } from '../model/index';
import { DrawRoundService } from './draw-round.service';

@Injectable()
export class RoundTimerService {

    nextRounds: DrawRound[];
    currentDrawRound: DrawRound;
    subscriptions: any[] = [];
    dateTime: Date;

    roundSubject: Subject<any> = new Subject<DrawRound>();
    public getSubject(): Subject<any> { return this.roundSubject; }

    constructor(private configGroup: ConfigGroupService, private drawRound: DrawRoundService) {
    }

    start() {
        this.stop();
        this.subscriptions.push(this.drawRound.getNext(20).subscribe(
            rounds => this.setDrawRounds(rounds['list']),
            err => this.subscriptions.push(observableTimer(5 * 1000).subscribe(
                (x) => this.start()
            ))
        ));
    }

    getNextRoundNumber() {
        if (this.currentDrawRound) {
            return this.currentDrawRound.number;
        }
        return null;
    }

    setDrawRounds(nextRounds: DrawRound[]): void {
        this.nextRounds = nextRounds;
        if (!this.nextRounds || this.nextRounds.length < 1) {
            this.subscriptions.push(observableTimer(3 * 1000).subscribe(
                (x) => this.start()
            ));
            return;
        }
        this.getTime();
    }

    private getTime() {
        this.currentDrawRound = null;
        const date1 = new Date();
        this.subscriptions.push(this.configGroup.getTime().subscribe(
            data => {
                this.dateTime = TimeUtil.convertUtcToLocalTime(data.dateTime);
                const date2 = new Date();
                let timeOffset = 0;
                if (date2 > date1) {
                    timeOffset = (date2.getTime() - date1.getTime()) / 2;
                    this.dateTime = new Date(this.dateTime.getTime() + timeOffset);
                }
                this.calculateRound();
            },
            error => this.subscriptions.push(observableTimer(3 * 1000).subscribe(
                (x) => this.getTime()
            ))
        ));
    }

    calculateRound(): void {
        let secondLeft;
        do {
            if (!this.nextRounds || !this.nextRounds[0]) {
                return;
            }

            this.nextRounds[0].drawTime = TimeUtil.convertUtcToLocalTime(this.nextRounds[0].drawingNumbersTimeUtc);
            secondLeft = TimeUtil.calculateDiffInSeconds(this.nextRounds[0].drawTime, this.dateTime);
            if (secondLeft < 1) { 
                this.nextRounds.shift();
            }
        } while (secondLeft < 1);
        this.currentDrawRound = this.nextRounds[0];
        this.subscriptions.push(observableTimer((secondLeft - 0.5) * 1000).subscribe(
            (x) => this.getTime()
        ));
        this.roundSubject.next(this.currentDrawRound);
    }

    publishBallEvent(data: any) {
        try {
            const numbers = data.arrayResult.splice(0, data.index);
            this.roundSubject.next({
                number: data.roundNumber,
                balls: numbers
            });
        } catch (err) {

        }
    }

    stop() {
        try {
            this.nextRounds = null;
            this.currentDrawRound = null;
            this.subscriptions.forEach(s => s.unsubscribe());
        } catch (e) { }
    }

}
