import { IBetContent } from './bet-content';
import { BetType } from './bet-type';

export class StringBetContent extends IBetContent {

    selection: string;

    constructor(betType: BetType, selection: string) {
        super(betType, selection);
        this.selection = selection;
    }

    onValueSelected(value: any): any {
        const select = this.selection;
        if (this.isValueSelected(value)) {
            this.selection = null;
        } else {
            this.selection = value;
        }
        return select;
    }

    isValueSelected(value: any): boolean {
        // tslint:disable-next-line:triple-equals
        if (this.selection == value) {
            return true;
        }
        return false;
    }

    isEmpty(): boolean {
        if (!this.selection) {
            return true;
        }
        return false;
    }

    isBetValid(): boolean {
        return !this.isEmpty();
    }

    getSelection(): string {
        return this.selection;
    }

    clear(): void {
        this.selection = null;
    }

}
