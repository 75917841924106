
import {map, timeout} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConfigGroup } from '../model/config-group';
import { Injectable } from '@angular/core';
import { BaseService } from '../common';
import { Observable } from 'rxjs';

@Injectable()
export class ConfigGroupService extends BaseService {

    public uri = 'config';
    config: ConfigGroup;
    currency: string;

    constructor(protected http: HttpClient) {
        super();
    }

    getConfig(): Observable<ConfigGroup> {
        if (this.config) {
            return Observable.create((observer) => {
                observer.next(this.config);
            });
        }
        return this.http.get<ConfigGroup>(this.uri, this.getOptions()).pipe(timeout(10000),map(
            (response) => {
                this.config = response;
                if (this.config) {
                    this.currency = this.config.currency;
                }
                return this.config;
            }),);
    }

    getTime() {
        return this.http.get<any>(this.uri + '/currenttime', this.getOptions()).pipe(timeout(10000));
    }

}