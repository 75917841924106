import { BALL_COLORS_VALUES } from '../component/model/data/balls-data';
import { BetContentFactory } from './bet-content-factory';
import { TicketBet } from './ticket-bet';
import { IBetContent } from './bet-content';
import { BetType } from './bet-type';
import { SelectionConverter } from './selection-converter';
import { BallsData } from '../component/model/data/balls-data';

export class BetConverter {

    /**
    * Converts list of IBetContent objects to a list of domain objects of type TicketBet.
    * If there are no bets, it will return an empty array.
    */
    public convertBetsToSelections(bets: IBetContent[]): TicketBet[] {
        if (!bets) {
            throw new Error('Bets are not defined!');
        }
        const ticketBets = new Array<TicketBet>();
        for (let index = 0; index < bets.length; index++) {
            if (!bets[index].isEmpty()) {
                ticketBets.push(this.convertBetToSelection(bets[index]));
            }
        }
        return ticketBets;
    }

    private convertBetToSelection(betContent: IBetContent): TicketBet {
        if (betContent.betType < 0) {
            return new TicketBet(BetType[0], betContent.getSelection());
        }
        return new TicketBet(BetType[betContent.betType], betContent.getSelection());
    }

    /**
* Converts list of IBetContent objects to a list of domain objects of type TicketBet.
* If there are no bets, it will return an empty array.
*/
    public convertSelectionsToBets(selections: TicketBet[]): IBetContent[] {
        if (!selections) {
            throw new Error('Bets are not defined!');
        }
        const ticketBets = new Array<IBetContent>();
        selections.forEach(singleBet => {
            switch (BetType[singleBet.betType]) {
                case -1:
                case BetType.SimpleBetting:
                case BetType.SystemBetting:
                    ticketBets.push(BetContentFactory.create(BetType[singleBet.betType],
                        SelectionConverter.convertSelectionToNumbers(singleBet.betContent)));
                    break;
                case BetType.FirstBallColor:
                    ticketBets.push(BetContentFactory.create(BetType[singleBet.betType],
                        SelectionConverter.convertSelectionToColors(singleBet.betContent)));
                    break;
                default:
                    ticketBets.push(BetContentFactory.create(BetType[singleBet.betType], singleBet.betContent));
            }

        });
        return ticketBets;
    }

    /**
    * When user clicks on Add bet button, bet should be added to array.
    * Exception is for AllColors BetType, where Bet is converter into 8 Bets.
     * @param bet
     */
    public convertBetForCreation(bet: IBetContent): IBetContent[] {
        if (!bet) {
            throw new Error('Bet is not defined!');
        }
        const bets = [];
        if (bet.betType === BetType.AllColors) {
            const colorsValues = BALL_COLORS_VALUES;
            colorsValues.forEach(color => {
                const numbers = BallsData.getNumbersByColor(color);
                bets.push(BetContentFactory.create(BetType.SimpleBetting, numbers));
            });
        } else if (bet.betType === BetType.ColorOfBalls) {
            const numbers = BallsData.getNumbersByColor(bet.getSelection());
            bets.push(BetContentFactory.create(BetType.SimpleBetting, numbers));
        } else {
            bets.push(bet);
        }
        return bets;
    }

    /**
* Converts list of Fast bets objects to a list of domain objects of type TicketBet.
* If there are no bets, it will return an empty array.
*/
    public convertFastBetsToBets(singleBet: any): IBetContent[] {
        if (!singleBet) {
            throw new Error('Bets are not defined!');
        }
        const ticketBets = new Array<IBetContent>();
        switch (singleBet.betType) {
            case 'SimpleBetting':
            case 'SystemBetting':
                ticketBets.push(BetContentFactory.create(BetType[singleBet.betType + ''],
                    SelectionConverter.convertSelectionToNumbers(singleBet.selection)));
                break;
            case 'FirstFiveEvenOdd':
            case 'FirstBallEvenOdd':
                ticketBets.push(BetContentFactory.create(BetType[singleBet.betType + ''],
                    SelectionConverter.convertSelectionToOddEven(singleBet.selection)));
                break;
            case 'FirstBallUnderOver':
            case 'SumOfFirstFiveUnderOver':
                ticketBets.push(BetContentFactory.create(BetType[singleBet.betType + ''],
                    SelectionConverter.convertSelectionTounderOver(singleBet.selection)));
                break;
            case 'FirstBallColor':
                ticketBets.push(BetContentFactory.create(BetType[singleBet.betType + ''], singleBet.selection.toLowerCase()));
                break;
            default:
                ticketBets.push(BetContentFactory.create(BetType[singleBet.betType + ''], singleBet.selection));
        }

        return ticketBets;
    }
}
