export class BallPosition {

    position: number;
    quota: number;
    number: number;
    sweetSpot: boolean;

    constructor(position?: number, number?: number, quota?: number) {
        this.position = position;
        this.quota = quota;
        this.number = number;
    }

}
