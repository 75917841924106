export class AlertMessage {
    message: string;
    type: AlertType;
    dismissable: boolean;
    duration: number;
}

export enum AlertType {
    Info = 1,
    Error = 2,
    Success = 3
}
