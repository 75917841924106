export const GAME_TITLE = 'Happy SIX';

export const BASE_HREF: string = "/happy6/";

// Route Consts
export const LOGIN_ROUTE = 'login';
export const HOME_ROUTE = 'home';
export const BETTING_ROUTE = 'betting';
export const GAME_ROUTE = 'game';
export const HELP_ROUTE = 'help';
export const PRC_HOME_ROUTE = 'home985158PRC'
export const PRC_GAME_ROUTE = 'game985158PRC'
export const PAYOUT_ROUTE = 'payout';

// Local Storage
export const TOKEN_KEY = 'session';
export const PARAM_USERNAME = 'username';
