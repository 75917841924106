export class Ball {

    baseBallsUrl = './assets/images/balls/empty/e_';

    number: number;
    color: string;
    selected = false;
    count: number;
    border: string

    constructor(number: number, color: string, border: string) {
        this.number = number;
        this.color = color;
        this.border = border;
    }
}
