/**
 * Type of bet.
 */
export enum BetType {

    /**
     * Simple bet on numbers. There can only 6 numbers in Selection.
     */
    SimpleBetting,

    /**
    * System bet on numbers. There can be up to 10 numbers in Selection.
    */
    SystemBetting,

    /**
     * What will be the color of the first ball. Possible values in Selection are: Red, Green, Blue, Purple, Brown, Yellow, Orange, Black
     */
    FirstBallColor,

    /**
 * First ball odd/even. Possible values are: Odd, Even
 */
    FirstBallEvenOdd,

    /**
     * First ball under/over X. Possible values are: Under, Over
     */
    FirstBallUnderOver,

        /**
 * Simple betting, containing 6 balls with the same color.
 */
    ColorOfBalls,

    /**
    * Among first five numbers more odd or even. Possible values are: Odd, Even
    */
    FirstFiveEvenOdd,

    /**
    * Select one number. that will appear among first 5 balls. Possible values are: Number between 1 and 48
    */
    NumberAmongFirstFive,

    /**
     * Sum of first 5 balls under/over X. Possible values are: Under, Over
     */
    SumOfFirstFiveUnderOver,

    /**
 * Combination of 8 ColorOfBalls bets.
 */
    AllColors

}
