/**
 * Status of the ticket.
 */
export enum TicketStatus {

    Active = 'Active',

    BillingPending = 'BillingPending',

    WinPaidOut = 'WinPaidOut',

    PaidOut = 'PaidOut',

    Rolledback = 'Rolledback',

    NotWinning = 'NotWinning'
}
