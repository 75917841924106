import { BetView } from './../bet-view';
import { BALL_COLORS, BALL_COLORS_DARK, BALL_COLORS_VALUES } from './balls-data';
import { SelectionView } from './../selection-view';
import { BetType } from '../../../model/bet-type';

export const BET_DESCS: string[] = ['SIMPLE_BET', 'SYSTEM_BET', 'FIRST_BALL_COLOR', 'FIRST_NUMBER',
    'FIRST_NUMBER_VALUE', 'COLOR_OF_BALLS', 'FIRST_FIVE_PARITY', 'FIRST_FIVE_NUMBER', 'SUM_FIVE'];

export class BetsData {

    public static getColorSelections(): SelectionView[] {
        const balls = new Array(8);
        for (let i = 0; i < 8; i++) {
            if (BALL_COLORS) {
                balls[i] = { 'color': BALL_COLORS[i], 'colorLight': BALL_COLORS_DARK[i], 'value': BALL_COLORS_VALUES[i] };
            }
        }
        return balls;
    }

    public static getDescByBetType(betType: BetType): string {
        if (betType < 0) {
            betType = 0;
        }
        return BET_DESCS[betType];
    }

    public static getDataByBetType(betType: BetType): any {
        switch (betType) {
            case BetType.FirstBallColor:
                return this.getColorSelections();
            case BetType.FirstBallUnderOver:
                return ADDITIONAL_BETS[0];
            case BetType.SumOfFirstFiveUnderOver:
                return ADDITIONAL_BETS[3];
        }
    }

}

export const COLORS_BET = { 'type': BetType.FirstBallColor, 'desc': 'FIRST_BALL_COLOR', 'selections': BetsData.getColorSelections() };

export const ADDITIONAL_BETS: BetView[] = [
    {
        'type': BetType.FirstBallUnderOver, 'desc': 'FIRST_NUMBER_VALUE', 'selections': [
            { 'value': 'under' },
            { 'value': 'over' }
        ]
    },
    {
        'type': BetType.FirstBallEvenOdd, 'desc': 'FIRST_NUMBER_PARITY', 'selections': [
            { 'value': 'odd' },
            { 'value': 'even' }
        ]
    },
    {
        'type': BetType.SumOfFirstFiveUnderOver, 'desc': 'SUM_FIVE', 'selections': [
            { 'value': 'under' },
            { 'value': 'over' }]
    },
    {
        'type': BetType.FirstFiveEvenOdd, 'desc': 'FIRST_FIVE_PARITY', 'selections': [
            { 'value': 'odd' },
            { 'value': 'even' }]
    },
];
