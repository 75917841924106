export class BetParams {

    minWinAmount: number;

    minPayin: number;

    maxPayin: number;

    numberOfComb: number;

    payinPerComb: number;
}
