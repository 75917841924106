import { Injectable } from '@angular/core';
import { TicketBet, BetType, BetConverter, IBetContent, BetContentFactory, SelectionConverter } from '../model/index';
import { BetParamsService } from './bet-params.service';

/**
* Service that contains List of IBetContent objects and current bet info.
*/
@Injectable()
export class BetService {

    bets: Array<IBetContent>;
    currentBet: IBetContent;
    public currentBetIndex: number;
    bettingEnabled = true;

    private betConverter: BetConverter = new BetConverter();

    constructor(private betParams: BetParamsService) {
        this.bets = new Array<IBetContent>();
    }

    /**
    * When user clicks on some value on the UI.
    */
    betSelected(betType: BetType, selection: any): void {
        if (this.currentBet && this.currentBet.isEqualsBetType(betType)) {
            this.currentBet.onValueSelected(selection);
            if (this.currentBet.isEmpty()) {
                this.currentBet = null;
            } else {
                this.checkBet();
            }
        } else {
            this.currentBet = BetContentFactory.create(betType, selection);
        }
    }

    /**
     * Checks wheter bet type is Simple bet, and wheter all balls are from same color.
     * If true, bet is converted to ColorOfBalls.
     */
    checkBet() {
        if (this.currentBet && this.currentBet.selection.length === 6) {
            const color = SelectionConverter.colorOfNumbersSelection(this.currentBet.getSelection());
            if (color != null) {
                this.betSelected(BetType.ColorOfBalls, color);
            }
        }
    }

    /**
    * Prepares bet with desired index for update. Bet data is copied on currentBet object.
    */
    prepareForUpdate(betIndex: number): void {
        if (betIndex === undefined || betIndex === null || betIndex < 0 || betIndex >= this.bets.length || !this.bettingEnabled) {
            return;
        }
        if (betIndex === this.currentBetIndex) {
            this.currentBet = null;
            this.currentBetIndex = null;
        } else {
            this.currentBet = BetContentFactory.create(this.bets[betIndex].betType, this.bets[betIndex].selection);
            this.currentBetIndex = betIndex;
            this.checkBet();
        }
    }

    addFastBet(currentBet: any): boolean {
        if (!currentBet || !this.bettingEnabled) {
            return false;
        }
        let newIndex = this.bets.length;
        let offset = 0;
        if (this.currentBetIndex != null) {
            newIndex = this.currentBetIndex;
            offset = -1;
        }
        
        const newBets = this.betConverter.convertFastBetsToBets(currentBet);

        if ((this.bets.length + newBets.length + offset) > 10) {
            return false;
        }
        this.currentBet = null;
        this.currentBetIndex = null;
        if (newIndex < this.bets.length) {
            this.bets.splice(newIndex, 1, ...newBets);
        } else {
            this.bets.splice(newIndex, 0, ...newBets);
        }
        this.betParams.calculateBetParams(this.bets);
        return true;
    }

    /**
    * When user explicitly clicks on Аdd bet button.
    * If currentBet.index is different from null, that bet will be updated.
    * Returns true if bet is added, false if not.
    */
    addOrUpdateBet(): boolean {
        if (!this.currentBet || !this.currentBet.isBetValid() || !this.bettingEnabled) {
            return false;
        }
        let newIndex = this.bets.length;
        let offset = 0;
        if (this.currentBetIndex != null) {
            newIndex = this.currentBetIndex;
            offset = -1;
        }
        const newBets = this.betConverter.convertBetForCreation(this.currentBet);
        
        if ((this.bets.length + newBets.length + offset) > 10) {
            return false;
        }
        this.currentBet = null;
        this.currentBetIndex = null;
        if (newIndex < this.bets.length) {
            this.bets.splice(newIndex, 1, ...newBets);
        } else {
            this.bets.splice(newIndex, 0, ...newBets);
        }
        this.betParams.calculateBetParams(this.bets);
        return true;
    }

    /**
    * When user wants to remove bet from the list.
    * If index is different than null, bet on that index will be removed. If index isn't defined, currentBet will only be removed.
    */
    clear(index?: number) {
        if (index == null || index === undefined) {
            this.currentBet = null;
        } else {
            if (index > -1 && index < this.bets.length) {
                this.bets.splice(index, 1);
                if (this.currentBet && this.currentBetIndex === index) {
                    this.currentBet = null;
                    this.currentBetIndex = null;
                } else if (this.currentBetIndex > index) {
                    this.currentBetIndex--;
                }
            }
        }
        this.betParams.calculateBetParams(this.bets);
    }

    /**
    * Clears all current bets.
    */
    clearAll() {
        this.bets.length = 0;
        this.currentBetIndex = null;
        this.betParams.calculateBetParams(this.bets);
    }

    /**
    * Method that converts array of IBetContent objects to TicketBets, that will be used later for ticket creation.
    */
    getBetSelections(): TicketBet[] {
        return this.betConverter.convertBetsToSelections(this.bets);
    }

    /**
* Method that converts array of IBetContent objects to TicketBets, that will be used later for ticket creation.
*/
    setBets(ticketBets: TicketBet[]) {
        this.clearAll();
        this.bets.splice(0, 0, ...this.betConverter.convertSelectionsToBets(ticketBets));
    }

}
