import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertMessage, AlertType } from '../model/index';

@Injectable()
export class AlertService {
    public messageSubject: Subject<AlertMessage> = new Subject<AlertMessage>();

    constructor(private translateService: TranslateService) {
    }

    getSubject(): Subject<any> {
        return this.messageSubject;
    }

    showAlert(msg: string, type: AlertType, duration?: number, dismissable?: boolean, notTranslate?: boolean) {
        let msg1 = '';
        if (dismissable === undefined) {
            dismissable = true;
        }
        if (duration === undefined) {
            duration = 4000;
        }
        if (!msg) {
            return;
        }
        try {
            if (notTranslate) {
                msg1 = msg;
            } else {
                msg.split('.').forEach(element => {
                    if (element) {
                        this.translateService.get(element).subscribe(
                            (res: string) => msg1 += res + ' ',
                            err => msg1 += element + '. ');
                    }
                });
            }
        } catch (err) {
            msg1 = msg;
        }
        const alertObj: AlertMessage = { message: msg1, type: type, dismissable: dismissable, duration: duration };
        this.messageSubject.next(alertObj);
    }
}
