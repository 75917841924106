
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';

/**
 * Every Http service should extend this class.
 * Because all Http services share same url and some other methods.
 */
export abstract class BaseService {

  static appUrl = 'baseApiUrl';

  constructor() {
  }

  logError(error: HttpResponse<any>) {
    try {
      const errorData = error.body;
      console.error(errorData.error);
    } catch (e) {
      console.error(error);
    }
    return observableThrowError(error);
  }

  protected getOptions(params1?: HttpParams) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json');
    headers = headers.set('Accept', 'application/json');
    
    if (params1) {
      return {
        params: params1,
        headers: headers
      };
    }
    return {
      headers: headers
    };
  }

}
