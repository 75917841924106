import { BaseEntity } from '../common';
import { Dictionary } from './dictionary';

/**
 * Single bet on a ticket. A ticket can contain multiple bets. Each bet is independent of other bets.
 */
export class TicketBet {

    /**
    * Type of a Bet.
    */
    betType: string;

    /**
    * Bet content.
    */
    betContent?: string;
    selection?: string;

    betResults?: string;

    betContentArray?: any[];
    resultsDictionary?: Dictionary;

    /**
    * Pay in amount for this ticket. PayinAmount = GameStakeAmount + ServiceChargeAmount.
    */
    payinAmount?: number;

    payinTaxAmount?: number;

    payoutAmount?: number;

    payoutTaxAmount?: number;

    /**
    * Service charge amount. ServiceChargeAmount = PayinAmount * (configured percentage).
    */
    serviceChargeAmount?: number;

    status?: string;

    /**
    * Amount to be used for win calculation. GameStakeAmount = PayinAmount - ServiceChargeAmount.
    */
    gameStakeAmount?: number;

    constructor(betType: string, selection: string) {
        this.betType = betType;
        this.selection = selection;
    }

}
