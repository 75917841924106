
import {timeout} from 'rxjs/operators';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { BaseEntity } from '../model/base-entity';
import { BaseService } from './base.service';

export abstract class CrudService<T extends BaseEntity> extends BaseService {

    constructor(public uri: string, protected http: HttpClient) {
        super();
    }

    getAllFilter(page?: number, size?: number, name?: string) {
        let params: HttpParams = new HttpParams();
        if (name) {
            params = params.set('name', name);
        }
        if (page) {
            page--;
            params = params.set('page', page.toString());
        }

        params = params.set('sort', 'id,asc');

        if (size) {
            params = params.set('size', size.toString());
        }
        return this.http.get(this.uri + '/filter', this.getOptions(params)).pipe(timeout(10000));
    }

    getAll() {
        return this.http.get(this.uri).pipe(timeout(10000));
    }

    getOne(id: number) {
        return this.http.get<BaseEntity>(this.uri + '/' + id, this.getOptions()).pipe(timeout(10000));
    }

    create(entity: BaseEntity) {
        return this.http.post<BaseEntity>(this.uri, entity, this.getOptions());
    }

    update(entity: BaseEntity) {
        return this.http.put<BaseEntity>(this.uri + '/' + entity.getId(), entity, this.getOptions()).pipe(timeout(10000));
    }

    delete(id: number) {
        return this.http.delete(this.uri + '/' + id, this.getOptions()).pipe(timeout(10000));
    }

}
