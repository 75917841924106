import { BaseEntity } from '../common/index';
import { RoundResult } from './round-result';

/**
* Information about one Draw Round.
*/
export class DrawRound extends BaseEntity {

    drawRoundId?: number;

    /**
* Number of the round(this number resets each day).
*/
    number: number;

    /**
    * Drawing animation start time of the round.
    */
    drawingNumbersTimeUtc: string;

    drawTime?: Date;

    startRoundTimeUtc: string;

    /**
     * Undefined if draw round is scheduled. Contains data if round is finished.
     */
    result?: RoundResult;

    constructor(number: number, drawingNumbersTimeUtc: string) {
        super();
        this.number = number;
        this.drawingNumbersTimeUtc = drawingNumbersTimeUtc;
    }

    getId(): number {
        return this.drawRoundId;
    }

}
