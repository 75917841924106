import { BaseEntity } from './../common/index';

/**
 * Config group info for one company.
 */
export class ConfigGroup extends BaseEntity {

    configGroupId: string;

    /**
     * Short desc about this config group.
     */
    description: string;

    /**
     * Min payin for all terminals that use this config group.
     */
    minPayin: number;

    /**
    * Max payin for all terminals that use this config group.
    */
    maxPayin: number;

    /**
    * Min numbers for the bet.
    */
    minPick: number;

    /**
    * Max numnbers for system bet.
    */
    maxSystem: number;

    jackpotActive: boolean;

    /**
    * Service charge for all terminals that use this config group.
    */
    serviceCharge: number;

    // Quotas
    oFirstOddEven: number;

    oFirstUnderOver_24_5: number;

    oFirst_5_UnderOver_122_5: number;

    oFirst_5_OddEven: number;

    oFirstIn_5: number;

    colorQuotas: any;

    oStandardQuotas: any;

    currency: string;

    getId(): any {
        return this.configGroupId;
    }
}
