/**
* All util methods regarding Time/Date are here.
*/
export class TimeUtil {

    public static formatDate(date: Date): string {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return ('0' + day).slice(-2) + '.' + ('0' + month).slice(-2) + '.' + year;
    }

    public static formatTime(date: Date, showSeconds?: true): string {
        const hour = date.getHours();
        const min = date.getMinutes();
        let sec;
        let time = ('0' + hour).slice(-2) + ':' + ('0' + min).slice(-2);
        if (showSeconds) {
            sec = date.getSeconds();
            time += ':' + ('0' + sec).slice(-2);
        }
        return time;
    }

    /**
    * Converts json string to Date object, with local time info.
    */
    public static convertUtcToLocalTime(jsonTime: string): Date {
        // const stringLength = jsonTime.length;
        // const lastChar = jsonTime.charAt(stringLength - 1);
        // if (lastChar !== 'Z') {
        //     jsonTime += 'Z';
        // }
        const timeLocal = new Date(jsonTime);
        return timeLocal;
    }

    public static calculateDiffInSeconds(date1: Date, date2: any): number {
        return (date1.getTime() - date2) / 1000;
    }

}
