
import {timer as observableTimer,  Observable } from 'rxjs';
import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponent} from './common/index';
import { AlertMessage } from './model/index';
import { AlertService } from './service/index';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends BaseComponent {

  timerSub: any;
  alert: AlertMessage;

  constructor(private alertService: AlertService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscriptions.push(this.alertService.getSubject().subscribe(
      message => this.onAlertEvent(message)));
  }

  ngOnDestroy() {
    this.dismissAlert();
    super.ngOnDestroy();
  }

  onAlertEvent(message) {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
    this.alert = message;
    if (message.duration) {
      this.timerSub = observableTimer(message.duration).subscribe((x) => this.dismissAlert());
    }
  }

  dismissAlert() {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
    this.alert = null;
  }

}
