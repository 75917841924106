import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { BaseService } from '../service/base.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private auth: AuthService) { }

    /**
     * Intercept every HTTP request and appends token, if present.
     * Also adds URL prefix to every API call.
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.auth.getToken();
        let request1 = request;
        if (!request.url.startsWith('./')) {
            request1 = request.clone({ url: BaseService.appUrl + request.url });
        }
        if (token) {
            const newRequest = request1.clone({ headers: request.headers.set('token', token) });
            return next.handle(newRequest);
        }
        return next.handle(request1);
    }

}
