/**
 * Every domain class should extend this class and implement getId() method.
 */
export class BaseEntity {

    id?: any;

    /**
     * Method that should be implemented, because different implementations may have different id attributes.
     */
    getId(): any {
        return this.id;
    }
}
