
import { throwError as observableThrowError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { LOGIN_ROUTE } from './../constants';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(public auth: AuthService, private inj: Injector) { }

    /**
     * Intercept every HTTP response, and redirect to Home Page, if 401 is returned.
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err: any, caught) => {
                console.log(err);
                if (err && err.error && err.error.includes("UserAuthenticationException")) {
                    const router = this.inj.get(Router);
                    router.navigate([LOGIN_ROUTE], { queryParams: { returnUrl: router.url } });
                } else {
                    return observableThrowError(err);
                }
            }));
    }

    generateCustomMessage(err: any) {

    }
}