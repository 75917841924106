import { Ball } from './../ball';
export const BALL_COLORS_VALUES: string[] = ['red', 'green', 'blue', 'purple', 'brown', 'yellow', 'orange', 'black'];
export let BALL_COLORS: string[] = ['#FF0000', '#7EBC3E', '#29ABE2', '#C5005D', '#A87C4F', '#F9B01F', '#FF7524', '#828487'];
export let BALL_COLORS_DARK: string[] = ['#621313', '#44562D', '#244C5D', '#541E34', '#493C2E', '#675923', '#643A21', '#454546'];

export class BallsData {

    public static getBalls(): Ball[][] {
        const balls = new Array(6);
        for (let i = 0; i < 6; i++) {
            balls[i] = new Array(8);
        }
        for (let i = 0; i < 6; i++) {
            for (let j = 0; j < 8; j++) {
                balls[i][j] = new Ball(i * 8 + j + 1, BALL_COLORS_VALUES[j], BALL_COLORS[j]);
            }
        }
        return balls;
    }

    public static getBall(value: number): Ball {
        if (value < 0 || value > 48) {
            throw new Error('Value not in good range');
        }
        const ball = new Ball(value, BALL_COLORS_VALUES[(value - 1) % 8], BALL_COLORS[(value - 1) % 8]);
        return ball;
    }

    public static getColors(): string[] {
        return BALL_COLORS_VALUES;
    }

    public static getNumbersByColor(value: string): number[] {
        const numbers = new Array(6);
        const j = BALL_COLORS_VALUES.indexOf(value);
        for (let i = 0; i < 6; i++) {
            numbers[i] = i * 8 + j + 1;
        }
        return numbers;
    }

    public static getBallColor(value: number): string {
        return BALL_COLORS_VALUES[(value - 1) % 8];
    }

}
