
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';


import { BaseService } from '../common/index';

@Injectable()
export class AppConfig {

  private config: Object = null;
  private env: Object = null;

  constructor(private http: HttpClient) {
  }

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any) {
    return this.config[key];
  }

  /**
   * Use to get the data found in the first file (env file)
   */
  public getEnv(key: any) {
    return this.env[key];
  }

  /**
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'prod', 'dev')
   *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.dev.json')
   */
  public load() {
    return new Promise((resolve, reject) => {
      this.http.get<any>('./assets/config.json').pipe(catchError((error: any): any => {
        console.log('Configuration file "env.json" could not be read');
        resolve(error);
        return observableThrowError(error.json().error || 'Server error');
      })).subscribe((responseData) => {
        this.config = responseData;
        BaseService.appUrl = this.config['BACKEND_URL'];
        resolve(true);
      });

    });
  }

}
