import { RoundResult } from './round-result';
import { BaseEntity } from '../common/index';
import { DrawRoundPhase } from './draw-round-phase';

/**
* Information about one Draw Round.
*/
export class DrawRoundStatus extends BaseEntity {

    /**
    * Number of the round(this number resets each day).
    */
    number: number;

    phaseName: DrawRoundPhase;

    totalDuration: number;

    secondLeft: number;

    drawRoundId: number;

    /**
     * Undefined if draw round is scheduled. Contains data if round is finished.
     */
    result?: RoundResult;

    getId(): number {
        return this.number;
    }

}
