
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { HOME_ROUTE } from './../constants';


import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(public auth: AuthService, private inj: Injector) { }

    /**
     * Intercept every HTTP response, and redirect to Home Page, if 401 is returned.
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            /* event logging
            .map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && ~(event.status / 100) > 3) {
                    console.info('HttpResponse::event =', event, ';');
                } else console.info('event =', event, ';');
                return event;
            })*/
            catchError((err: any, caught) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.auth.removeUserData();
                        const router = this.inj.get(Router);
                        if (!router.url.substring(1, router.url.length).startsWith(HOME_ROUTE)) {
                            router.navigate([HOME_ROUTE], { queryParams: { returnUrl: router.url } });
                        } else {
                            window.location.reload();
                        }
                    }
                }
                return observableThrowError(err);
            }));
    }

}
