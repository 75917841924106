import { StringBetContent } from './string-bet-content';
import { BetType } from './bet-type';
import { BALL_COLORS_VALUES } from '../component/model/data/balls-data';

export class AllColorsBetContent extends StringBetContent {

    selection: string;
    allColors: string[] = BALL_COLORS_VALUES;

    constructor(selection: string) {
        super(BetType.AllColors, selection);
        this.selection = selection;
    }

    isValueSelected(value: any): boolean {
        if (this.allColors.indexOf(value) > -1) {
            return true;
        }
        return super.isValueSelected(value);
    }

    isEqualsBetType(betType: BetType): boolean {
        return this.betType === betType || betType === BetType.ColorOfBalls;
    }

}
