import { IBetContent } from './bet-content';
import { BetType } from './bet-type';

export class ColorsBetContent extends IBetContent {

    selection: Array<string>;

    constructor(colors: any) {
        super(BetType.FirstBallColor);
        this.selection = new Array<string>();
        if (colors) {
            if (colors instanceof Array) {
                this.selection.push.apply(this.selection, colors);
            } else {
                this.selection.push(colors);
            }
        }
    }

    onValueSelected(value: any): any {
        if (this.isValueSelected(value)) {
            this.selection.splice(this.selection.indexOf(value), 1);
            return value;
        } else {
            if (this.selection.length < 4) {
                this.selection.push(value);
            }
        }
    }

    isValueSelected(value: any): boolean {
        if (this.selection.indexOf(value) > -1) {
            return true;
        }
        return false;
    }

    isEmpty(): boolean {
        if (this.selection && this.selection.length > 0) {
            return false;
        }
        return true;
    }

    isBetValid(): boolean {
        if (this.isEmpty()) {
            return false;
        }
        if (this.selection.length !== 3) {
            return true;
        }
        return false;
    }

    getSelection(): string {
        if (!this.selection) {
            return null;
        }
        let selectionBuilder = '';
        this.selection.forEach(element => {
            selectionBuilder += ', ' + element.charAt(0).toUpperCase() + element.slice(1);
        });
        selectionBuilder = selectionBuilder.substring(2);
        return selectionBuilder;
    }

    getNumberOfCombinations(): number {
        if (this.selection) {
            return this.selection.length;
        }
        return 0;
    }

    clear(): void {
        this.selection = new Array<string>();
    }

}
